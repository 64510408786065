@import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-react-calendars/styles/material.css";

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-link {
  color: #61dafb;
}

input[type="text"],input[type="password"] {
  border-radius: 6.666666667px !important;
  font-size: 16px !important;

  transform: scale(0.75) !important;
  transform-origin: left top !important;

  margin-bottom: -10px !important;
  margin-right: -33.333333333% !important;
}

@media screen and (-webkit-min-device-pixel-ratio:0) { 
  select,
  textarea,
  input {
    font-size: 16px !important;
  }
}

.loader {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  display: none;
  z-index: 999;
  position: relative;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/*@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}*/
#begin{
  text-align: justify;
  font-size: 14px;
}

.back{
  background-image: url(./images/Homepage/HomepageArrow.png);
  width: 30px;
  height: 40px;
  position: absolute;
  background-size: contain;
  background-repeat: no-repeat;
  -webkit-transform:rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
  left: 20px;
}

.WhiteBack{
  background-image: url(./images/Homepage/whiteArrow.png);
  width: 30px;
  height: 40px;
  position: absolute;
  background-size: contain;
  background-repeat: no-repeat;
  -webkit-transform:rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
  left: 20px;
}

.x{
  width: 15px;
  height: 15px;
  top: 20px;
  position: relative;
  margin-left: 15px;
  background: url(./images/Homepage/x.png);
  background-size: contain;
  background-repeat: no-repeat;
}


/* Splash.js */
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.splashP{
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 110%;
  /* or 15px */

  letter-spacing: 0.42em;

  color: #FFFFFF;
}

/* end Splash.js */


/* OnBoardingOne.js */
  .OnBoardingOne{
    background-image: url(./images/OnBoarding/OnBoardingOneBg.png);
    /* background-size: cover; */
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 100%;
    position: fixed;
  }

  .OnBoardingOneH{

    position: relative;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    text-align: center;
    height: 20px;

    color: #6B563B;
  }

  .OnBoardingOneP{
    position: relative;

    /* H4 */

    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: center;

    color: #A48155;
  }

  .OnBoardingOneDivOne{
    top: 70%;
    position: relative;
    width: 80%;
    display: inline-block;
  }

  .OnBoardingOneDivImg{
    position: absolute;
    width: 100%;
    bottom: 50px;
  }

  .OnBoardingOneDivLogin{
    position: absolute;
    bottom: 10px;
    width: 100%;
  }

  .OnBoardingOneDivLogin p{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 19px;
    /* identical to box height */

    text-align: center;

    color: black;
  }

  .OnBoardingOneDivLogin a{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 19px;
    /* identical to box height */

    text-align: center;

    color: blue;
    text-decoration: none;
  }

  .OnBoardingOneNext{
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */

    text-align: center;

    color: #A48155;
    text-decoration: none;

    right: 10px;
    top: 25px;
    position: absolute;
  }
/* end OnBoardingOne.js */


/* OnBoardingTwo.js */
.OnBoardingTwo{
  background-image: url(./images/OnBoarding/OnBoardingTwoBg.png);
  /* background-size: cover; */
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: fixed;
}

.OnBoardingTwoH{

  position: relative;

  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
  height: 30px;

  color:#8B808C;

}

.OnBoardingTwoP{
  position: relative;

  /* H4 */

  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: center;

  color: #7B707B;
}

.OnBoardingTwoDiv{
  top: 70%;
  position: relative;
  width: 80%;
  display: inline-block;
}

.OnBoardingTwoDivImg{
  position: absolute;
  bottom: 50px;
  width: 100%;
}

.OnBoardingTwoDivLogin{
  position: absolute;
  bottom: 10px;
  width: 100%;
}

.OnBoardingTwoDivLogin p{
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 19px;
  /* identical to box height */

  text-align: center;

  color: black;
}

.OnBoardingTwoDivLogin a{
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 19px;
  /* identical to box height */

  text-align: center;

  color: blue;
  text-decoration: none;
}

.OnBoardingTwoNext{
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  top: 10px;
  /* identical to box height */

  text-align: center;

  color: #A48155;
  text-decoration: none;

  right: 10px;
  top: 25px;
  position: absolute;
}

/* end OnBoardingTwo.js */

/* OnBoardingThree.js */
.OnBoardingThree{
  background-image: url(./images/OnBoarding/OnBoardingThreeBg.png);
  
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 100%;
    position: fixed;
}

.OnBoardingThreeH{

  position: relative;

  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 20px;
  text-align: center;
  height: 10px;

  color:#677789;

}

.OnBoardingThreeP{
  position: relative;

  /* H4 */

  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: center;

  color: #879AAF;
}

.OnBoardingThreeDiv{
  top: 70%;
  position: relative;
}

.OnBoardingThreeDivImg{
  position: relative;
  top: 68%;
}

.OnBoardingThreeDivLogin{
  position: absolute;
  bottom: 20px;
  width: 100%;
}

.OnBoardingThreeDivLogin p{
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 19px;
  /* identical to box height */

  text-align: center;

  color: black;
}

.OnBoardingThreeDivLogin a{
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 19px;
  /* identical to box height */

  text-align: center;

  color: blue;
  text-decoration: none;
}

.OnBoardingThreeNext{
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  top: 10px;
  /* identical to box height */

  text-align: center;

  color: #8A9CB0;
  text-decoration: none;

  right: 10px;
  top: 25px;
  position: absolute;
}

/* end OnBoardingThree.js */

/* Login.js */

  .LoginDivMain{
    position: fixed;
    width: 100%;
    height: 100%;
    background:url(./images/Authentication/Log_in.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }

  .LoginDivOne{
    position: relative;
  }

  .LoginDivH{
    position: relative;
    color: #FFFFFF;

    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    
    bottom: -10px;
  }

  .LoginDivP{
    position: relative;
    color: #FFFFFF;

    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #939AA8;
  }

  .LoginDivCredentials{
    display: inline-grid;
    position: relative;
    width: 90%;
    overflow: unset;
  }

  .CredentialsPass{
    margin-top: 15px;

     border: 1px solid #798293;
     border-radius: 4px;
     background-color: inherit;
     box-sizing: border-box;
     height: 25px;
     color: white;

     
     font-family: 'Roboto', sans-serif;
     font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 16px;
  }

  .CredentialsUser{
    border: 1px solid #798293;
     border-radius: 4px;
     background-color: inherit;
     box-sizing: border-box;
     height: 25px;
     color: white;

     font-family: 'Roboto', sans-serif;
     font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 16px;
     
  }

  .LoginRememberMe{
    position: relative;
    display: flex;
    align-items: center;
    left: 20px;
  }

  .RememberMeCheck{
      display: flex;
      align-items: center;
      position: relative;
  }

  .RememberMeCheck input{
    width: 15px;
    height: 15px;
    appearance: none;

    border: 1px solid #00DAFF;
    border-radius: 3px;
    box-sizing: border-box;
    place-content: center;
}

.RememberMeCheck input::before{
  content: "";
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
  background-color: CanvasText;
 
}

.RememberMeCheck input:checked{
  transform: scale(1);
  background-color: #00DAFF;
  
}

.RememberMeCheck p{
  left: 5px;
  position: relative;

  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 110%;
  /* or 15px */


color: #00DAFF;
}

.RememberMeCheck a{
  left: 5px;
  position: relative;

  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 110%;
  /* or 15px */
}

.LoginRememberMe a{
  right: 35px;
  position: absolute;
  text-decoration: none;

  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 110%;
  /* or 15px */


  /* Color 3 */

  color: #68727D;
}

.LoginDivButton{
  position: relative;

}

.LoginDivButton button{
  position: relative;
  width: 150px;
  height: 30px;

  background: #00AFCD;
  box-shadow: 0px 24px 54px rgba(0, 175, 205, 0.22);
  border-radius: 7px;
  border: none;

  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: white;
  
}

.LoginDivRegister{
  position: relative;
}

.LoginDivRegister p{
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;

  /* Color 1 */

  color: #939AA8;

}

.LoginDivRegister a{
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-decoration: none;

  /* Color 1 */

  color: #FFFFFF;

}

.SomethingWentWrong{
  color: red;
  position: relative;
}

#mail::placeholder{
  font-family: 'Roboto',sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;

  color: #FFFFFF;
}

#pass::placeholder{
  font-family: 'Roboto',sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}

.LoginDivSpace{
  height: 300px;
}

/* end Login.js */

/* Map.js */
  .mapsBegin{
    position: relative;
    top: 40px;
  }

  .mapsBegin p{
     
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
  }

  .mapsBegin ul{
     margin-top: -10px;
     margin-left: -10px;
  }

  .MapsConfirmButton{
    top: 90%;
    position: absolute;
    width: 100%;
  }

  .MapsConfirmButton button{
    flex-direction: column;
    align-items: center;
    padding: 14px 86px;
    gap: 10px; 
    background: #00AFCD;
    box-shadow: 0px 24px 54px rgb(0 175 205 / 22%);
    border-radius: 7px;
    border: none;
  }
/* end Map.js*/

/* Register.js */

.RegisterDivMain{
  position: fixed;
  width: 100%;
  height: 100%;
  /*background:url(./images/Authentication/Create_Profile.png);*/
  background-color: #182021;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.RegisterDivOne{
  position: relative;
}

.RegisterDivH{
  position: relative;
  color: #FFFFFF;

  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  
  bottom: -10px;
}

.RegisterDivP{
  position: relative;
  color: #FFFFFF;

  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #939AA8;
}

.SomethingWentWrongRegister{
  position: absolute;
  width: 100%;
  bottom: 100px;
  height: 25px;
  color: red;
}

.RegisterDivCredentials{
  display: inline-grid;
  position: relative;
  width: 90%;
}

.CredentialsPass{
  margin-top: 15px;

   border: 1px solid #798293;
   border-radius: 4px;
   background-color: inherit;
   box-sizing: border-box;
   height: 35px;
   color: white;

   
   font-family: 'Roboto', sans-serif;
   font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
}

.CredentialsUser{
  margin-top: 15px;
  border: 1px solid #798293;
   border-radius: 4px;
   background-color: inherit;
   box-sizing: border-box;
   height: 35px;
   color: white;

   font-family: 'Roboto', sans-serif;
   font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
   
}

.CredentialsName{
  border: 1px solid #798293;
   border-radius: 4px;
   background-color: inherit;
   box-sizing: border-box;
   height: 35px;
   color: white;

   font-family: 'Roboto', sans-serif;
   font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
   
}

.CredentialsName::placeholder{
    color: white;

    font-family: 'Roboto', sans-serif;
    font-style: normal;
   font-weight: 400;
   font-size: 14px;
   line-height: 16px;
}

.RegisterDivButton{
  position: absolute;
  width: 100%;

}

.RegisterDivButton button{
position: relative;
width: 150px;
height: 30px;

background: #00AFCD;
box-shadow: 0px 24px 54px rgba(0, 175, 205, 0.22);
border-radius: 7px;
border: none;

font-family: 'Roboto', sans-serif;
font-style: normal;
font-weight: 400;
font-size: 14px;
color: white;

}

.RegisterDivRegister{
position: relative;
}

.RegisterDivRegister p{
font-family: 'Roboto', sans-serif;
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 16px;

/* Color 1 */

color: #939AA8;

}

.RegisterDivRegister a{
font-family: 'Roboto', sans-serif;
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 16px;
text-decoration: none;

/* Color 1 */

color: #FFFFFF;

}

.SomethingWentWrong{
color: red;
top: 51%;
position: relative;
}

/* end Register.js */

/* ForgotPass.js */

.ForgotPassDivMain{
  position: fixed;
  width: 100%;
  height: 100%;
  /*background:url(./images/Authentication/Forgot_Password.png)*/
  background-color: #182021;
}

.ForgotPassDivOne{
  position: relative;
}

.ForgotPassDivH{
  position: relative;
  color: #FFFFFF;

  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  
  bottom: -10px;
}

.ForgotPassDivP{
  position: relative;
  color: #FFFFFF;

  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #939AA8;
}

.ForgotPassDivCredentials{
  display: inline-grid;
  position: relative;
  width: 90%;
}

.CredentialsUser{
  margin-top: 15px;
  border: 1px solid #798293;
   border-radius: 4px;
   background-color: inherit;
   box-sizing: border-box;
   height: 35px;
   color: white;

   font-family: 'Roboto', sans-serif;
   font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
   
}


.ForgotPassRememberMe{
  top: 35%;
  position: relative;
  display: flex;
  align-items: center;
  left: 20px;
}


.ForgotPassDivButton{
  position: relative;
  margin-top: 10px;

}

.ForgotPassDivButton button{
position: relative;
width: 150px;
height: 30px;

background: #00AFCD;
box-shadow: 0px 24px 54px rgba(0, 175, 205, 0.22);
border-radius: 7px;
border: none;

font-family: 'Roboto', sans-serif;
font-style: normal;
font-weight: 400;
font-size: 14px;
color: white;

}

.ForgotPassDivForgotPass{
position: relative;
top:50%;
}

.ForgotPassDivForgotPass p{
font-family: 'Roboto', sans-serif;
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 16px;

/* Color 1 */

color: #939AA8;

}

.ForgotPassDivForgotPass a{
font-family: 'Roboto', sans-serif;
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 16px;
text-decoration: none;

/* Color 1 */

color: #FFFFFF;

}

.SomethingWentWrong{
  color: red;
  top: 51%;
  position: relative;
}

/* end ForgotPass.js */

/* SendMailSuccess.js */
  .SendMailSuccessDivMain{
    position: fixed;
    width: 100%;
    height: 100%;
    background: url(./images/Authentication/Email_check.png);
  }

  .SendMailSuccessDivOne{
    position: relative;
    top: 53%;
  }
  
  .SendMailSuccessDivH{
    position: relative;
    color: #FFFFFF;
  
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    
    bottom: -10px;
  }
  
  .SendMailSuccessDivP{
    position: relative;
    color: #FFFFFF;
  
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #939AA8;
  }

  .SendMailSuccessDivButton{
    position: relative;
    top: 55%;
    
    }
    
    .SendMailSuccessDivButton button{
    position: relative;
    width: 150px;
    height: 30px;
    
    background: #00AFCD;
    box-shadow: 0px 24px 54px rgba(0, 175, 205, 0.22);
    border-radius: 7px;
    border: none;
    
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: white;
    
    }

    
/* end SendMailSuccess.js */

/* Calendar.js */
    .react-calendar{
      width: 80% !important;
      top:120px !important;
      position: relative;
      background: #4C565F !important;
      border-radius: 6px;
      display: inline-block;

      font-family: 'Roboto', sans-serif !important;
      color: #FFFFFF !important;
      top: 30px !important;
    }

    .myCalendar h2{
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 32px;
      line-height: 110.8%;
      /* or 35px */

      text-align: center;
      top: 55px;
      position: relative;

      /* Color 5 */

      color: #31373E;
    }

    .myCalendar p{
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 110.8%;
      /* or 35px */

      text-align: center;
      top: 35px;
      position: relative;

      /* Color 5 */

      color: #31373E;
    }

    .timePicker p{
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 110.8%;
      /* or 35px */

      text-align: center;
      top: 5px;
      position: relative;

      /* Color 5 */

      color: #31373E;
    }

    .react-calendar__tile{
      color: white;
      border-radius: 30px !important;
    }

    .react-calendar__tile--now{
      background: #00AFCD !important;
    }

    .react-calendar__tile--active{
      background: #798293 !important;
    }

    .timePicker{
      margin-top: 20%;
      position: absolute;
      width: 100%;
    }

    .timePicker input{
      border: none;
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 32px;
      line-height: 110.8%;
      /* or 35px */

      text-align: center;

      /* Color 5 */

      color: #31373E;
    }

    .confirmDate{
      position: absolute;
      /* display: inline-block; */
      /* top: 350px; */
      width: 100%;
      bottom: 80px;
    }

    .confirmDate button{
      position: relative;
     
      height: 30px;

      background: #00AFCD;
      box-shadow: 0px 24px 54px rgba(0, 175, 205, 0.22);
      border-radius: 7px;
      border: none;

      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: white;
    }

    input[type=time]::-webkit-calendar-picker-indicator {
      background-image: url(./images/Homepage/watch.png);
    }

    .CalendarDetails{
      width: 100%;
      position: absolute;
      bottom: 180px;
    }

    .CalendarDetails h3{
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      color: black;
    }

    .CalendarDetails textarea{
      width: 70%;
      height: 100px;
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      border-radius: 10px;
    }

    .SomethingWentWrongCalendar{
      color: red;
      bottom: 260px;
      /* position: relative; */
      position: absolute;
      width: 100%;
    }
/* end Calendar.js */

/* RouteResults.js */
  .RouteResultsMainDiv{
    position: absolute;
    width: 100%;
    height: 100%;
    background: url(./images/Homepage/Home.png);
  }

  .RouteResultsblackEclipse{
    width: 100%;
    height: 87%;
    position: absolute;
    overflow: auto;
    bottom: 0;
    background-size: 100% 100% !important;
    background-repeat: no-repeat !important;
    background: url(./images/Homepage/Rectangle\ 76.png);
    border: none;
  }

  .routes{
    position: relative;
    width: 75%;
    display: inline-block;
    height: 180px;
    margin-top: 10px;
    background: #FFFFFF;
    border-radius: 3px;
  }

  .RoutesDiv{
    position: relative;
    top: 5px;

    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    /* identical to box height */


    /* Color 5 */

    color: #31373E;

    left: 15px;

  }

  .RoutesDivImages{
    display: inline-grid;
    position: absolute;
    left: 20px;
  }

  .RoutesDivRegions{
    position: relative;
    margin-left: 20px;
  }

  .RoutesDivRegions{
    position: relative;
    margin-left: 40px;
  }
  
  .RoutesDivRegions p{
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    /* identical to box height */


    /* Color 2 */

    color: black;
    text-decoration: none;
    text-align: left;
  }

  .passInfo{
    position: absolute;
    left: 0%;
    right: 0%;
    top: 75%;
    bottom: 0%;

    /* Light Gray */

    background: #E7EAEF;
    border-radius: 3px;
    display: flex;
    align-items: center;
  }

  .time{
    font-family: 'Roboto',sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
  
    color: #31373E;
    position: relative;
    margin-left: 10px;
  }

  .seats{
    font-family: 'Roboto',sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;

    color: #31373E;
    position: relative;
    margin-left: 10px;
  }

  .passInfo img{
    width: 15px;
    height: 17px;
    margin-left: 20px;
  }

  .seatImg{
    position: relative;
    margin-left: 30px !important;
  }

  .driverProfile{
    display: flex;
  }

  .deleteProfile{
    background-color: red !important;
    color: white !important;
  }

  .deleteProfile p{
    color: white !important;
  }

  .driverInfo{
    position: relative;
    top: 10%;
    left: 10%;
    height: 30px;
  }

  .driverInfo .driverName{
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */
    text-align: left;


    /* Color 5 */

    color: #31373E;
  }

  .driverInfo .driverCar{
    /* Paragraph */

    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 0px;
    /* identical to box height */


    
    top: -10px;
    position: relative;
    text-align: left;

    color: #939AA8;
  }

  .driverImage{
    width: 45px;
    height: 45px;
    position: relative;
    left: 10px;
    top: 5px;
  }

  .driversLicenceInput{
    width: 15px !important;
    height: 15px !important;
    appearance: none !important;
    border: 1px solid #00DAFF !important;
    border-radius: 3px !important;
    box-sizing: border-box !important;
    place-content: center !important;
  }
/*end RouteResults.js */

/* Homepage.js */
  .HomepageMainDiv{
    position: fixed;
    width: 100%;
    height: 100%;
    background: url(./images/Homepage/Home.png);
    background-size: 100% 100%;
  }

  .blackEclipse{
    width: 100%;
    height: 35%;
    position: absolute;
    bottom: 0;
    background-size: 100% 100% !important;
    background-repeat: no-repeat;
    background: url(./images/Homepage/Rectangle\ 76.png);
    border: none;
  }

  .menu{
    width: 100%;
    height: 35%;
    position: absolute;
    bottom: -20px;
    background-size: cover !important;
    background-repeat: no-repeat;
    background: url(./images/Homepage/Group_59.png);
    z-index: 1;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
  }

  .car{
    background: url(./images/Homepage/My\ Rides.png);
    position: relative;
    width: 24px;
    height: 24px;
    left: 10px;
    bottom: 40px;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .messages{
    background: url(./images/Homepage/Chat.png);
    position: relative;
    width: 24px;
    height: 24px;
    background-size: contain;
    background-repeat: no-repeat;
    bottom: 40px;
  }

  .notifications{
    background: url(./images/Homepage/Vector.png);
    position: relative;
    width: 24px;
    height: 24px;
    background-size: contain;
    background-repeat: no-repeat;
    bottom: 40px;
  }

  .profile{
    background: url(./images/Homepage/Profile.png);
    position: relative;
    width: 24px;
    right: 10px;
    height: 24px;
    background-size: contain;
    background-repeat: no-repeat;
    bottom: 40px;
  }

  .searchButtonDiv{
    position: relative;
  }

  .searchButtonDiv button{
    position: relative;
    width: 150px;
    height: 40px;

    background: #00AFCD;
    box-shadow: 0px 24px 54px rgba(0, 175, 205, 0.22);
    border-radius: 7px;
    border: none;

    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: white;
  }

  .HomepageReservation{
    color: white;
    position: relative;
    display: inline-flex;
  }

  .HomepageCalendar{
    background: url(./images/Homepage/Rectangle\ 77\ \(2\).png);
    width: 80px;
    height: 90px;
    background-repeat: no-repeat;
    top: -40px;
    position: relative;
    background-size: contain;
  }

  .HomepagePassengers{
    background: url(./images/Homepage/Rectangle\ 77\ \(2\).png);
    width: 80px;
    height: 90px;
    background-repeat: no-repeat;
    top: -40px;
    position: relative;
    background-size: contain;
    right: -10px;
  }

  .HomepagePassengers img{
    top: 10px;
    position: relative;
  }

  .HomepageCalendar img{
    top: 10px;
    position: relative;
  }

  .date .day{
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 12px;
    /* identical to box height */
    top: 5px;
    position: relative;

    text-align: center;

    color: #FFFFFF;
  }

  .date .hour{
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 12px;
    /* identical to box height */

    text-align: center;

    color: #FFFFFF;
  }

  .StartDestination{
    position: relative;
    display: inline-block;
    height: 120px;
    width: 70%;
    top: 100px;
    background: #FFFFFF;
    box-shadow: 0px 34px 34px #182021;
    border-radius: 6px;
  }

  .OfferOrGive{
    position: relative;
  }

  .OfferOrGive button{
    position: relative;
    width: 114px;
    height: 36px;
    top:50px;
    

    background: #939AA8;
    box-shadow: 0px 21px 41px rgba(43, 46, 50, 0.42);
    border-radius: 100px;
    border: none;

    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;

    color: #FFFFFF;

  }

  hr{
    position: relative;
    width: 180%;
    height: 1.5px;
    background-color:  #DCDCDC;
    border: none;
  }

  .StartDestination a{
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    /* identical to box height */


    /* Color 2 */

    color: #798293;
    text-decoration: none;
    position: relative;
    text-align: left;
    width: 100%;
    display: flex;
  }

  .StartDestination p{
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    /* identical to box height */


    /* Color 2 */

    color: black;
    text-decoration: none;
    text-align: left;
  }

  .searchButtonMenu{
    position: absolute;
    bottom: 47px;
    width: 100%;
  }

  .searchButtonMenu button{
    background: #798293;
    box-shadow: 0px 34px 44px rgba(0, 0, 0, 0.2);
    border-radius: 50px;
    border: none;
    width: 70px;
    height: 68px;
  }

  .HomepageRoutesDiv{
    position: absolute;
    top: 5px;

    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    /* identical to box height */


    /* Color 5 */

    color: #31373E;

    display: inline-flex;
    align-items: center;
    left: 40px;
}

.HomepageRoutesDivImages{
  display: inline-grid;
  top: 20px;
  position: absolute;
  left: -15px;
}

.SomethingWentWrongHomepage{
  color: red;
  top: 51%;
  /* position: relative; */
  position: absolute;
  width: 100%;
}

.NoTripsFound{
  color: red;
  top: 49%;
  /* position: relative; */
  position: absolute;
  width: 100%;
}

 .menuLogo{
    position: absolute;
    width: 100%;
    bottom: 30px;
 }

 .menuLogo img{
  width: 45px;
  height: 35px;
 }
/* end Homepage.js */

/* ConfirmRoute.js */ 
  .confirmRouteMainDiv{
    position: fixed;
    width: 100%;
    height: 100%;
    background: whitesmoke;
  }

  .confirmRouteMainDiv h1{
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 110.8%;
    /* or 35px */
    top: 50px;
    position: relative;

    text-align: center;

    /* Color 5 */

    color: #31373E;
  }

  .confirmRouteButton{
    position: relative;
    top: 50%;
  }

  .confirmRouteButton a{
    text-decoration: none;
    background: #00AFCD;
    box-shadow: 0px 24px 54px rgba(0, 175, 205, 0.22);
    border-radius: 7px;
    border: none;

    padding: 14px 86px;
    gap: 10px;

    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */


    color: #FFFFFF;
  }

  .RouteStart{
    position: relative;
    text-align: left;
  }

  .RouteEnd{
    position: relative;
    text-align: left;
  }

  .ConfirmRoutesDiv{
    position: relative;
    top: 30px;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #31373E;
    display: inline-flex;
    align-items: center;
    width: 100%;
  }

  .ConfirmRoutesDivImages{
    display: inline-grid;
    top: 20px;
    position: absolute;
    left: 50px;
    
  }

  .ConfirmRoutesDivRegions{
    position: relative;
    margin-left: 65px;
    margin-top: 10px;
  }

  .ConfirmRouteDate{
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    position: absolute;

    color: #000000;
  }

  .ConfirmRouteTime{
    position: absolute;
    left: 5px;
    top: 10px;

    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    /* identical to box height */


    /* Color 1 */

    color: #939AA8;
  }

  .ConfirmRouteStar{
    position: absolute;
    right: 40px;
    display: flex;
    align-items: center;
  }

  .ConfirmRouteStar img{
    height: 15px;
    width: 15px;
  }

  .ConfirmRouteStar p{
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    /* identical to box height */
    
    
    /* Color 2 */
    
    color: #798293;
    top: 2px;
    left: 5px;
    position: relative;
  }
/* end ConfirmRoute.js */


/* SeatBooking.js */ 
.confirmRouteMainDiv{
  position: fixed;
  width: 100%;
  height: 100%;
  background: whitesmoke;
}

.SeatBookingMainDiv h1{
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 110.8%;
  /* or 35px */
  top: 50px;
  position: relative;

  text-align: center;

  /* Color 5 */

  color: #31373E;
}

.SeatBookingButton{
  position: relative;
  margin-top: 170%;
}

.SeatBookingButton button{
  background: #00AFCD;
  box-shadow: 0px 24px 54px rgba(0, 175, 205, 0.22);
  border-radius: 7px;
  border: none;

  padding: 14px 86px;
  gap: 10px;

  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */


  color: #FFFFFF;
}

.seatsDiv {
  position: absolute;
  width: 65%;
  height: 70%;
  background-size: cover !important;
  
  top: 120px;
  border-radius: 3px;

  background: url(./images/Homepage/car\ 1.png);
}

.seatsButtons{
    display: inline-flex;
    flex-flow: column;
    position: absolute;
    top: 50%;
}

.seatsButtons button{
  background: #00AFCD;
  width: 50px;
  height: 50px;
  border: none;
  border-radius: 30px;
}

.driverSeat{
  background: url(./images/logo.png) !important;
  background-size: 100% 100% !important;
}

.reservationResponse{
  position: absolute;
  bottom: 140px;
  color: red;
  /* text-align: center; */
  /* display: inline-block; */
  /* left: 50%; */
  width: 100%;
}
/* end SeatBooking.js */

/* SuccessfullReservation.js */
  .SuccessfullReservationMainDiv{
    position: fixed;
    background-color:  #595C64;
    width: 100%;
    height: 100%;
  }

  .SuccessfullReservationMainDiv h1{
    position: relative;
    top: 400px;

    /* H1 */

    font-family: 'Roboto',sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    text-align: center;

    /* Light Gray */

    color: #E7EAEF;
  }

  .SuccessfullReservationMainDiv p{
    position: relative;
    top: 400px;

    /* H1 */

    font-family: 'Roboto',sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    text-align: center;

    /* Color 1 */

    color: #E7EAEF;
  }

  .SuccessfullButton{
    position: absolute;
    bottom: 50px;
    width: 100%;
  }

  .SuccessfullButton button{
    position: relative;
    width: 150px;
    height: 50px;
  
    background: #00AFCD;
    box-shadow: 0px 24px 54px rgba(0, 175, 205, 0.22);
    border-radius: 7px;
    border: none;
  
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: white;
  }
/* end SuccessfullReservation.js */

/* HistoryRoutes.js */
  .HistoryRoutesMainDiv{
    position: fixed;
    width: 100%;
    height: 100%;
    background: whitesmoke;
    overflow: auto;
  }

  .HistoryRoutesH{
    position: absolute;
    width: 267px;
    height: 30px;
    left: 29px;
    top: 73px;

    /* H1 */

    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    /* identical to box height */


    /* Color 5 */

    color: #31373E;
  }

  .PendingHistoryRoutesH{
    position: relative;

    /* H1 */

    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    /* identical to box height */


    /* Color 5 */

    color: #31373E;
  }

  .HistoryMainRoutes{
    position: relative;
    top: 20%;
  }

  .HistoryMainRoutes button{
    position: relative;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;

    /* Color 4 */

    color: #4C565F;
    text-decoration: none;
    border: none;
    background: none;
    border-radius: 20px;
    height: 30px;
  }

  .HistoryRoutes {
    position: relative;
    width: 65%;
    display: inline-block;
    height: 180px;
    margin-top: 10px;
    background: #FFFFFF;
    border-radius: 3px;
  }

  .NoRoutes{
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 30px;
    /* identical to box height */


    /* Color 5 */

    color: #31373E;
  }

  .MyRoutes{
    position: relative;
    width: 75%;
    display: inline-block;
    height: 150px;
    top: 30px;
    background: #FFFFFF;
    border-radius: 3px;
  }

  .History{
    position: relative;
    top: 180px;
  }

  .HistoryRoutesDiv{
      position:absolute;
      top: 5px;
      width: 100%;
  
      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 14px;
  
      color: #31373E;

      display: inline-flex;
      align-items: center;
      left: 20px;
  }

  .HistoryRoutesDivImages{
    display: grid;
  }

  .HistoryRoutesDivRegions{
    top: 5px;
    position: relative;
    width: 100%;
    font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 14px;
  }

  .HistoryRoutesLine{
    position: absolute !important;
    width: 100% !important;
    bottom: 80px;
  }

  .HistoryRoutesPassInfo{
    position: absolute;
    bottom: 30px;
    display: flex;
    width: 100%;
  }

  .HistoryDriverProfile{
    display: grid;
    top: 5px;
    position: relative;
  }

  .HistoryDriverImage{
    width: 35px;
    height: 35px;
    position: relative;
    left: 10px;
    top: 5px;
  }

  .HistoryDriverInfo{
    position: relative;
    top: 0px;
    left: 5px;
    height: 30px;

    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    /* identical to box height */

    text-align: center;

    /* Color 1 */

    color: #939AA8;
  }

  .HistoryCarInfo{
    position: absolute;
    right: 10px;
    top: 10px;
  }

  .HistoryCarInfoDate{
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    /* identical to box height */

    text-align: center;

    /* Color 1 */

    color: #939AA8;
  }

  .HistoryCarInfoCar{
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    /* identical to box height */

    text-align: center;

    /* Color 1 */

    color: #939AA8;
    position: relative;
    top: -10px;
  }

  .HistoryCarInfoStatus{
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    /* identical to box height */
    height: 15px;

    /* Color 1 */

    color: #FFFFFF;
    position: relative;

    background: #939AA8;
    border-radius: 3px;
    
  }

  .HistoryCarInfoStatusComplete{
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    /* identical to box height */
    height: 15px;

    /* Color 1 */

    color: #13CF97;
    position: relative;

    background: #D7FDF1;
    border-radius: 3px;
    
  }

  .HistoryCarInfoStatusCanceled{
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    /* identical to box height */
    height: 15px;

    /* Color 1 */

    color: #CF1313;
    position: relative;

    background: #FDD7D7;
    border-radius: 3px;
    
  }

  .AddRoute{
    position: absolute;
    /* position: relative; */
    /* bottom: 10px; */
    display: inline-grid;
    right: 10px;
  }

  .AddRoute a{
    position: relative;
    width: 50px;
    height: 35px;

    background: #00AFCD;
    box-shadow: 0px 24px 54px rgba(0, 175, 205, 0.22);
    border-radius: 7px;
    border: none;

    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: white;
    text-decoration: none;
    display: inline-flex;
  }

  .AddRoute button{
    position: relative;
    width: 40px;
    height: 35px;

    background: #00AFCD;
    box-shadow: 0px 24px 54px rgba(0, 175, 205, 0.22);
    border-radius: 7px;
    border: none;

    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: white;
    top: 5px;
  }

  .AddRouteDel{
    background: url(./images/Homepage/delete.png) !important;
    background-size: 100% 100% !important;
    top: 15px !important;
    width: 50px !important;
    right: 5px !important;
  }

  .AddRouteNewButton{
    position: absolute;
    width: 100%;
    bottom: 10px;
  }

  .AddRouteNewButton button{
    position: relative;
    width: 150px;
    height: 45px;

    background: #00AFCD;
    box-shadow: 0px 24px 54px rgba(0, 175, 205, 0.22);
    border-radius: 7px;
    border: none;

    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: white;
  }

/*end HistoryRoutes.js*/

/* MyProfile.js */
  .MyProfile{
    position: fixed;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    top:-30px;
  }

  .MyProfileH{
    position: absolute;
    width: 202px;
    height: 30px;
    left: 29px;
    top: 73px;

    /* H1 */

    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    /* identical to box height */


    /* Color 5 */

    color: #31373E;
  }

  .ProfileImageDiv{
    position: relative;
    background-size: cover;
    width: 120px;
    height: 120px;
    display: inline-block;
    top: 150px;
  }

  .ProfileImageDiv img{
    position: relative;
    top: 20px;
  }

  .ProfileInfo{
    position: relative;
    display: grid;
    top: 120px;
    padding: 60px;
    width: 70%;
  }

  .ProfileInfo label{
    position: relative;
    left: 0px;

    /* H5 */

    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 12px;
    /* identical to box height */

    color: #31373E;
    
  }

  .ProfileInfo input{
    border: 1px solid #141517;
    border-radius: 4px;
    height: 25px;
    margin-top: 10px;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 12px;
  }

  .ProfileInfo select{
    border: 1px solid #141517;
    border-radius: 4px;
    height: 30px;
    margin-top: 10px;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 12px;
  }

  .UpdateDivButton{
    position: absolute;
    width: 100%;
    margin-top: 100px;
  }

  .UpdateDivButton button {
    background: #00AFCD;
    box-shadow: 0px 24px 54px rgba(0, 175, 205, 0.22);
    border-radius: 7px;
    width: 223px;
    height: 47px;

    border: none;

    font-family: 'Roboto',sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    /* identical to box height */


    color: #FFFFFF;

  }

/* end MyProfile.js */


/* Profile.js */
  .Profile{
    position: fixed;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: whitesmoke;
  }

  .ProfileChoises{
    position: absolute;
    width: 100%;
    bottom: 100px;
  }

  .ProfileChoises div{
    position: relative;
    background: #FFFFFF;
    /* Shadow 2 */

    box-shadow: 0px 34px 34px #EFEFF2;
    border-radius: 6px;
    width: 100%;
    height: 40px;
  }

  .ProfileChoises img{
    width: 14px;
    height: 15px;
    position: absolute;
  }

  .ProfileChoises p{
    position: relative;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
  
    color: #798293;
    top: 10px;
  }

  .ProfileMail p{
    font-family: 'Roboto',sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    /* identical to box height */

    text-align: center;

    /* Color 3 */

    color: #68727D;
  }

  .ProfilePhone p{
    font-family: 'Roboto',sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    /* identical to box height */

    text-align: center;

    /* Color 3 */

    color: #68727D;
  }

  .ProfileDiv{
    display: flex;
    align-items: center;
    position: relative;
    top: 100px;
    right: 20px;
    left: 20px;
  }

  .ProfileName{
    font-family: 'Roboto',sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    line-height: 98.8%;
    /* or 32px */


    color: #000000;
  }

  .GenericProfileImageDiv input{
    opacity: 0;
    height: 100%;
    position: absolute;
    left: 0;
  }


  .ProfileImage{
    /*background: url(./images/MyProfile/ProfileImageFake.png);*/
    width: 150px;
    height: 140px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    left: 50px;
    top: 10px;
    border-radius: 80px;
  }

/* end Profile.js */


/* PassengerRideInfo.js */
  .PassengerRideInfo{
    width: 100%;
    position: fixed;
    height: 100%;
    background-color: #FFFFFF;
    overflow: auto;
  }

  .DriverStars{
    position: relative;
    /* align-items: center; */
    /* display: inline-flex; */
    justify-content: center;
    align-items: center;
    background: #31373E;
    display: inline-flex;
    border-radius: 4px;
    height: 24px;
    width: 50px;
    top: 40px;
    left: 30px;
  }

  .DriverStars img{
    height: 14px;
  }

  .DriverStars p{
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    /* identical to box height */
    color: #FFFFFF;

  }

  .NameAndVehicle{
    position: relative;
    display: inline;
    top: 15px;
    left: 15px;
  }

  .ProfileAndStars{
    position: absolute;
    top: 45px;
    display: flex;
  }

  .NameAndVehicle h3{
    font-family: 'Roboto',sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    /* identical to box height */
    height: 15px;
    text-align: left;


    /* Color 5 */

    color: #31373E;
  }

  .NameAndVehicle p{
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    /* identical to box height */
    
    
    /* Color 2 */
    
    color: #798293;
  }

  .PassengerRideInfoblackEclipse{
    width: 100%;
    height: 75%;
    position: absolute;
    bottom: 0;
    background-size: 100% 100% !important;
    background-repeat: no-repeat;
    background: url(./images/Homepage/Rectangle\ 76.png);
    border: none;
  }

  .CancelDiv{
    position: absolute;
    width: 100%;
    bottom: 60px;
    margin-top: 10px;
  }

  .CancelDiv button{
    position: relative;
    background: #FF5473;
    box-shadow: 0px 24px 54px rgba(255, 84, 115, 0.16);
    border-radius: 7px;
    border: none;
    width: 250px;
    height: 47px;

    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */


    color: #FFFFFF;

  }

  .PassengerRideInfoRoutesDiv{
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    color: #31373E;
    position: relative;
    display: inline-block;
    top: -20px;
  }

  .PassengerRideInfoRoutesDivImages{
    display: grid;
    top: 50px;
    position: relative;
  }

  .PassengerRideInfoRoutesDivRegions{
    position: relative;
    top: 5px;
    margin-left: 20px;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;

    color: #9AA5B3;

  }

  .DriverEvaluation{
    position: fixed;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    height: 30%;
  }

  .EvaluationPassengerProfile{
    position: relative;
    display: inline-flex;
    align-items: center;
    width: 100%;
    height: 60px;
  }

  .EvaluationPassengerImage{
    width: 35px;
    height: 35px;
    position: relative;
    left: 15px;
    top: 5px;
  }

  .EvaluationOfPassenger{
    position: relative;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    text-align: left;
    color: #939AA8;
    left: 25px;
  }

  .Passengers{
    position: relative;
    height: 100px;
    width: 100%;
  }

  .Passengers p{
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;

    color: #FFFFFF;
  }

  .PassengersProfiles{
    height: 45px;
    position: relative;
    display: inline-block;
    left: -115px;
    padding-left: 15px;
  }

  .PassengersProfiles p{
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    /* identical to box height */

    text-align: center;

    /* Color 2 */

    color: #798293;
    position: relative;
    top: -10px;
  }

  .PassengersProfiles img{
    width: 35px;
    height: 35px;
  }

  .passengerName{
    font-family: 'Roboto',sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */


    /* Color 1 */

    color: #939AA8;
  }

  .actualEvaluation{
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    
    /* Color 3 */
    
    color: #68727D;
    top: -11px;
    position: relative;
  }

/* end PassengerRideInfo.js */

/* EditRoutes.js */

  .EditRoutes{
    position: absolute;
    width: 100%;
    height: 100%;
    background: #FFFFFF;
    border-radius: 3px;
  }

  .dates{
    position: absolute;
    margin-top: 160px;
  }

  .dates p{
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
  }

  .deleteButton{
    position: relative;
    background: url(./images/Homepage/delete.png);
    background-size: 100% 100%;
    box-shadow: 0px 24px 54px rgba(255, 84, 115, 0.16);
    border-radius: 7px;
    border: none;
    width: 80px;
    height: 55px;
    

    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */
    left: 10px;


    color: #FFFFFF;
  }

  .editButton{
    position: relative;
    background:#00AFCD;
    background-size: 100% 100%;
    box-shadow: 0px 24px 54px rgba(255, 84, 115, 0.16);
    border-radius: 7px;
    border: none;
    width: 65px;
    height: 55px;
    

    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */
    left: 10px;


    color: #FFFFFF;
  }

  .datesInfo{
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    left: 25px;
  }

  .CancelDivEditRoute{
    position: absolute;
    width: 100%;
    bottom: 60px;
    margin-top: 10px;
  }

  .CancelDivEditRoute button{
    position: relative;
    background: #FF5473;
    box-shadow: 0px 24px 54px rgba(255, 84, 115, 0.16);
    border-radius: 7px;
    border: none;
    width: 250px;
    height: 40px;

    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    /* identical to box height */


    color: #FFFFFF;
  }

  .HistoryRouteInfo{
    position: relative;
    display: grid;
    /* padding: 60px; */
    width: 80%;
    width: 100%;
  }

  .HistoryRouteInfo label{
    position: relative;
    left: 0px;

    /* H5 */

    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 12px;
    /* identical to box height */


    /* Color 5 */

    color: #31373E;
    
  }

  .HistoryRouteInfo input{
    border: 1px solid #141517;
    border-radius: 4px;
    height: 25px;
    margin-top: 10px;
  }

  .HistoryRouteInfo textarea{
    border: 1px solid #141517;
    border-radius: 4px;
    height: 55px;
    margin-top: 10px;
  }

  .UpdateRoute{
    position: absolute;
    width: 100%;
    bottom: 170px;
  }

  .UpdateRoute button{
    background: #00AFCD;
    box-shadow: 0px 24px 54px rgba(0, 175, 205, 0.22);
    border-radius: 7px;
    width: 223px;
    height: 47px;

    border: none;

    font-family: 'Roboto',sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    /* identical to box height */


    color: #FFFFFF;
  }

  .EditRoutesDivRegions{
    top: 5px;
    /* position: relative; */
    position: absolute;
    /* width: 100%; */
    width: 90%;
  }
/* end EditRoutes.js */

/* Chat.js */
  .ChatList{
    position: fixed;
    width: 100%;
    height: 100%;
    overflow: auto;
  }

  .ChatList h1{
    position:absolute;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    /* identical to box height */


    /* Color 5 */

    color: #31373E;
    top: 50px;
    left: 50px;
  }
/* end Chat.js */


/* Terms.js */
  .Terms{
    position: fixed;
    width: 100%;
    height: 100%;
    overflow: auto;
  }

  .TermsImageDiv{
    position: relative;
    background-size: cover;
    width: 120px;
    height: 120px;
    display: inline-block;
    top: 150px;
    background: url(./images/MyProfile/cycle.png);
  }

  .TermsImageDiv img{
    position: relative;
    top: 30px;
  }

/* end Terms.js */


/* Notifications.js */
  .NotificationsMainDiv{
    width: 100%;
    height: 100%;
    position: fixed;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: whitesmoke;
  }

  .NotificationsDiv{
    position: absolute;
    width: 100%;
  }

  .Notification{
    background: #FFFFFF;
    /* Shadow 2 */

    box-shadow: 0px 34px 34px #EFEFF2;
    border-radius: 6px;

    position: relative;
    height: 120px;
    margin-top: 10px;
    
    display: flex;
  }

  .NotificationsPassengerInfo{

  }

  .NotificationsPassengerInfo p{
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 110%;
    /* identical to box height, or 18px */


    /* Color 5 */

    color: #31373E;
    text-align: justify;
    margin-left: 10px;
  }

  .NotificationsProfileImage{
    width: 48px;
    height: 48px;
    background-size: contain;
    border-radius: 30px;
  }

  .NotificationActions{
    position: absolute;
    width: 100%;
    bottom: 10px;
  }

  .NotificationActions button{
    position: relative;
    width: 80px;
    height: 30px;
    border: none;
    border-radius: 30px;

    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 110%;
    /* identical to box height, or 18px */


    /* Color 5 */

    color: #FFFFFF;
  }

  .tick{
    /*background: url(./images/Homepage/tick.jpg);
    background-size: cover;*/
    background-color: green;
  }

  .xbutton{
    /*background: url(./images/Homepage/xbutton.png);
    background-size: cover;*/
    background-color: red;
  }
/* end Notifications.js */


/* EditMyTrips.js */
  .datesTimes p{
    color: #FFFFFF;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
  }
/* end EditMyTrip.js */

/* Modal.js */

  /* Modal Button */
  /*.modalButton {
    position: fixed;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
    padding: 12px 24px;
  }*/

  .modalButton{
    background: none;
    color: blue;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    border: none;
    text-decoration: underline;
  }


  /* Modal */
  .overlay {
    /* background-color: rgba(0, 0, 0, 0.5); */
    position: fixed;
    width: 100%;
    height: 100%;
  }

  .modalContainer {
    max-width: 600px;
    width: 100%;
    position: fixed;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    /*display: flex;*/
    background-color: #ffffff;
    box-shadow: 0px 0px 18px 0px rgba(0, 0, 0, 0.75);
    border-radius: 8px;
  }

  .modalContainer img {
    width: 250px;
    object-fit: cover;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  .modalRight {
    width: 100%;
  }

  .closeBtn {
    position: fixed !important;
    right: 0px !important;
    left: 0px !important;
    font-size: 20px !important;
    left: 80% !important;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin-top: 3rem;
    padding: 1rem 2rem;
  }

  .btnContainer {
    display: flex;
    padding: 1rem 1rem;
  }
  .btnContainer button {
    width: 100%;
    margin: .5rem;
    padding: 16px 0;
    /* border: none; */
    border: 1px solid #411b57;
    /* color: #ffffff; */
  }

  .btnPrimary {
    background-color: #411b57;
    color: white;
  }

  .btnOutline {
    /* background-color: #a76a99; */
    background-color: white;
    color: #411b57;
  }

  .bold {
    font-weight: 600;
  }

  @media screen and (max-width: 500px) {
    .modalContainer {
      flex-direction: column;
      top: 0;
      left: 0;
      transform: none;
      width: 100%;
      height: 100vh;
    }
    .modalContainer img {
      width: 100%;
      max-height: 70vh;
      object-fit: cover;
    }

    .heading {
      margin: 1rem;
    }
  }

/*end Modal.js */

/*  screens */
@media screen and (max-height: 821px) {
    .searchButtonMenu{
      bottom: 38px;
    }

    .searchButtonMenu button{
      width: 69px;
      height: 70px;
    }
}

@media screen and (max-height: 700px) {
  .searchButtonMenu{
    bottom: 20px;
  }

  .searchButtonMenu button{
    width: 69px;
    height: 70px;
  }

  .CancelDiv{
    bottom: 10px;
  }

  .DriverEvaluation{
    height: 25%;
  }
}

@media screen and (min-height: 701px) and (max-height: 760px){
  .searchButtonMenu{
    bottom: 20px;
  }

  .searchButtonMenu button{
    width: 69px;
    height: 70px;
  }
}

@media screen and (min-height: 764px){
  

  .searchButtonMenu button{
    width: 69px;
    height: 70px;
  }
}

@media screen and (max-height: 660px) {
  .searchButtonMenu{
    bottom: 18px;
  }

  .searchButtonMenu button{
    width: 69px;
    height: 70px;
  }
}

@media screen and (max-height: 660px) {
  .searchButtonMenu{
    bottom: 18px;
  }

  .searchButtonMenu button{
    width: 69px;
    height: 70px;
  }

  .LoginDivSpace{
    height: 260px;
  }
}

@media screen and (max-height: 707px){
  .menuLogo{
    bottom: 10px;
 }  
}

@media screen and (max-height: 810px) {
  /*.UpdateDivButton{
    bottom: -160px;
  }*/

  .car{
    bottom: 20px;
  }

  .messages{
    bottom: 25px;
  }

  .notifications{
    bottom: 25px;
  }

  .profile{
    bottom: 25px;
  }
}

@media screen and (max-height: 807px) {
  .CalendarDetails{
    bottom: 100px;
  }

  .confirmDate{
    bottom: 60px;
  }
}

@media screen and (min-height: 1024px) {
 .History{
  top: 250px;
 }

 .ProfileInfo{
  width: 85%;
 }

 .car{
  left: 30px;
 }

 .profile{
  right: 30px;
 }
}